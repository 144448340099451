<script>
import SectionBlock from '@/components/general/SectionBlock'
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import Autocomplete from '@/components/general/Autocomplete'
import { required, email } from 'vuelidate/lib/validators'
import Loading from '@/components/general/Loading.vue'
export default {
  components: { SectionBlock, InputField, SelectField, Autocomplete, Loading },
  name: 'CompanyBrForm',
  data () {
    return {
      attemptingSave: false,
      isReady: false,
      states: [],
      cities: [],
      changeCities: '',
      formData: {
        socialName: '',
        fantasyName: '',
        cnpj: '',
        cityRegister: '',
        stateRegister: '',
        isSimples: false,
        email: '',
        phone: '',
        cep: '',
        state: '',
        city: '',
        city_id: '',
        neighborhood: '',
        street: '',
        number: '',
        complement: ''
      }
    }
  },
  validations: {
    formData: {
      socialName: { required },
      fantasyName: { required },
      cnpj: { required },
      phone: { required },
      email: { required, email },
      neighborhood: { required },
      street: { required },
      number: { required },
      cep: { required }
    }
  },
  created () {
    this.getCompanyDetails()
    this.getStates()
  },
  methods: {
    getCompanyDetails () {
      this.$store.dispatch('attemptGetCompanyDetails')
        .then(({ data }) => {
          this.isReady = true
          this.getCities({ city: data.address.city.name, state: data.address.state.name })
          this.formData.socialName = data.corporateName
          this.formData.fantasyName = data.businessName
          this.formData.cnpj = data.cnpj
          this.formData.cityRegister = data.municipalRegistration
          this.formData.stateRegister = data.stateRegistration
          this.formData.isSimples = data.simplesNacionalOptin
          this.formData.email = data.email
          this.formData.phone = data.phone
          this.formData.cep = data.address.zipcode
          this.formData.state = data.address.state.id
          this.formData.city_id = data.address.city.id
          this.formData.neighborhood = data.address.neighborhood
          this.formData.street = data.address.street
          this.formData.number = data.address.number
          this.formData.complement = data.address.complement
        })
    },
    getCEP () {
      if (this.formData.cep.length !== 10) return
      this.$store.dispatch('attemptGetCEP', this.formData.cep.replace('.', '').replace('-', ''))
        .then(({ data }) => {
          this.formData.number = null
          this.formData.complement = null
          let state = []
          if (data.uf) state = this.states.filter(s => s.abbr === data.uf)
          if (data.localidade) this.getCities({ city: data.localidade, state: state[0].text })
          if (data.bairro) this.formData.neighborhood = data.bairro
          if (data.logradouro) this.formData.street = data.logradouro
          if (data.complemento) this.formData.complement = data.complemento
          if (data.uf) this.formData.state = state[0].id
        })
    },
    getStates () {
      this.$store.dispatch('attemptGetStates', 'BRA')
        .then(({ data }) => {
          this.states = data.map(({ id, name, abbr }) => ({ id, text: name, abbr }))
        })
    },
    getCities (address) {
      address.country = 'BRA'
      this.$store.dispatch('attemptGetCities', address)
        .then(({ data }) => {
          if (data.length) {
            if (address.state) this.formData.city = data[0].name
            this.formData.city_id = data[0].id
            this.cities = data.map(({ id, name }) => ({ id, text: name }))
          }
        })
    },
    updateCompanyDetails () {
      this.attemptingSave = true
      const data = {
        corporateName: this.formData.socialName,
        businessName: this.formData.fantasyName,
        cnpj: this.formData.cnpj,
        municipalRegistration: this.formData.cityRegister,
        stateRegistration: this.formData.stateRegister,
        simplesNacionalOptin: this.formData.isSimples,
        email: this.formData.email,
        phone: this.formData.phone,
        address: {
          zipcode: this.formData.cep,
          state: { name: this.formData.state },
          city: { id: this.formData.city_id },
          neighborhood: this.formData.neighborhood,
          street: this.formData.street,
          number: this.formData.number,
          complement: this.formData.complement
        }
      }
      this.$store.dispatch('attemptUpdateCompanyDetails', data)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.labels:submit.success')
          })
        })
        .catch(err => {
          if (err) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('settings.admincenter.labels:submit.error')
            })
          }
        }).finally(() => { this.attemptingSave = false })
    }
  },
  watch: {
    changeCities (name) {
      if (name) this.getCities({ city: name, state: null })
    }
  }
}
</script>
<template>
  <div>
    <Loading v-if="!isReady" />
    <div class="company-br--form" v-else>
      <section-block title="admin.center.company:form.indentify">
        <input-field v-model="formData.socialName" outlined persistent-placeholder :validation="$v.formData.socialName"
          :label="$t('admin.center.company:form.indentify.social.name')"></input-field>
        <input-field v-model="formData.fantasyName" outlined persistent-placeholder
          :validation="$v.formData.fantasyName"
          :label="$t('admin.center.company:form.indentify.fantasy.name')"></input-field>
        <div class="form-space">
          <input-field v-model="formData.cnpj" outlined persistent-placeholder :validation="$v.formData.cnpj"
            :label="$t('admin.center.company:form.indentify.cnpj')" mask="##.###.###/####-##" class="form-space-input"></input-field>
          <input-field v-model="formData.cityRegister" outlined persistent-placeholder
            :label="$t('admin.center.company:form.indentify.city.register')"  class="form-space-input"></input-field>
          <input-field v-model="formData.stateRegister" outlined persistent-placeholder
            :label="$t('admin.center.company:form.indentify.state.register')"  class="form-space-input"></input-field>
        </div>
        <v-checkbox v-model="formData.isSimples" :label="$t('admin.center.company:form.indentify.isSimples')"
          :color="getPrimaryColor"></v-checkbox>
      </section-block>
      <section-block title="admin.center.company:form.contact">
        <input-field v-model="formData.email" outlined persistent-placeholder :validation="$v.formData.email"
          :label="$t('admin.center.company:form.contact.email')"></input-field>
        <input-field v-model="formData.phone" outlined persistent-placeholder :validation="$v.formData.phone"
          :label="$t('admin.center.company:form.contact.phone')" mask="(##) #####-####"></input-field>
      </section-block>
      <section-block title="admin.center.company:form.address">
        <div class="form-space">
          <input-field v-model="formData.cep" outlined persistent-placeholder :validation="$v.formData.cep"
            @change="getCEP" :label="$t('admin.center.company:form.address.cep')" mask="##.###-###" class="form-space-input-2"></input-field>
          <select-field outlined :items="states" v-model="formData.state" item-value="id"
            :label="$t('admin.center.company:form.address.state')" class="form-space-input-2"/>
        </div>
        <div class="form-space">
          <autocomplete :search-input.sync="changeCities" :items="cities" hide-no-data outlined clearable
            v-model="formData.city" prepend-inner-icon="" :label="$t('admin.center.company:form.address.city')" class="form-space-input-2" />
          <input-field v-model="formData.neighborhood" outlined persistent-placeholder
            :validation="$v.formData.neighborhood"
            :label="$t('admin.center.company:form.address.neighborhood')" class="form-space-input-2"></input-field>
        </div>
        <div class="form-space">
          <input-field v-model="formData.street" outlined persistent-placeholder :validation="$v.formData.street"
            :label="$t('admin.center.company:form.address.street')" class="form-space-input"></input-field>
          <input-field v-model="formData.number" outlined persistent-placeholder :validation="$v.formData.number"
            :label="$t('admin.center.company:form.address.number')" class="form-space-input"></input-field>
          <input-field v-model="formData.complement" outlined persistent-placeholder
            :label="$t('admin.center.company:form.address.complement')" class="form-space-input"></input-field>
        </div>
      </section-block>
      <div class="company-br--actions">
        <v-btn class="btn transform-unset" :disabled="$v.$invalid || attemptingSave" :color="getPrimaryColor"
          :dark="!($v.$invalid || attemptingSave)" @click="updateCompanyDetails">
          <v-icon v-if="!attemptingSave" class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>
          {{ $t(attemptingSave? 'global:wait' : 'global:save.changes') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.company-br--form {
  .form-space {
    display: flex;
    justify-content: space-between;

    .form-space-input{
        width: 32%;
      }

      .form-space-input-2{
        width: 49%;
      }
  }

  .company-br--actions {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .company-br--form {
    .form-space {
      flex-direction: column;

      .form-space-input,
      .form-space-input-2{
        width: 100%;
      }

    }
  }
}
</style>
