<script>
import SectionBlock from '@/components/general/SectionBlock'
import InputField from '@/components/general/InputField'
import SelectField from '@/components/general/SelectField'
import Autocomplete from '@/components/general/Autocomplete'
import { required, email } from 'vuelidate/lib/validators'
export default {
  components: { SectionBlock, InputField, SelectField, Autocomplete },
  name: 'CompanyUsForm',
  data () {
    return {
      attemptingSave: false,
      isReady: false,
      states: [],
      cities: [],
      changeCities: '',
      formData: {
        socialName: '',
        fantasyName: '',
        ein: '',
        email: '',
        phone: '',
        zipcode: '',
        state: '',
        city: '',
        city_id: '',
        street: ''
      }
    }
  },
  validations: {
    formData: {
      socialName: { required },
      fantasyName: { required },
      phone: { required },
      email: { required, email },
      street: { required },
      zipcode: { required }
    }
  },
  created () {
    this.getCompanyDetailsUs()
    this.getStatesUs()
  },
  methods: {
    getCompanyDetailsUs () {
      this.$store.dispatch('attemptGetCompanyDetailsUs')
        .then(({ data }) => {
          this.isReady = true
          this.getCitiesUs({ city: data.address.city.name, state: data.address.state.name })
          this.formData.socialName = data.corporateName
          this.formData.fantasyName = data.businessName
          this.formData.ein = data.ein
          this.formData.email = data.email
          this.formData.phone = data.phone
          this.formData.zipcode = data.address.zipcode
          this.formData.state = data.address.state.id
          this.formData.city_id = data.address.city.id
          this.formData.street = data.address.street
        })
    },
    getStatesUs () {
      this.$store.dispatch('attemptGetStates', 'USA')
        .then(({ data }) => {
          this.states = data.map(({ id, name, abbr }) => ({ id, text: name, abbr }))
        })
    },
    getCitiesUs (address) {
      address.country = 'USA'
      this.$store.dispatch('attemptGetCities', address)
        .then(({ data }) => {
          if (data.length) {
            if (address.state) this.formData.city = data[0].name
            this.formData.city_id = data[0].id
            this.cities = data.map(({ id, name }) => ({ id, text: name }))
          }
        })
    },
    updateCompanyDetailsUs () {
      this.attemptingSave = true
      const data = {
        corporateName: this.formData.socialName,
        businessName: this.formData.fantasyName,
        ein: this.formData.ein,
        email: this.formData.email,
        phone: this.formData.phone,
        address: {
          zipcode: this.formData.zipcode,
          state: { name: this.formData.state },
          city: { id: this.formData.city_id },
          street: this.formData.street
        }
      }
      this.$store.dispatch('attemptUpdateCompanyDetailsUs', data)
        .then(() => {
          this.$store.dispatch('attemptSetFeedbackMsg', {
            type: 'success',
            title: this.$t('settings.admincenter.labels:submit.success')
          })
        })
        .catch(err => {
          if (err) {
            this.$store.dispatch('attemptSetFeedbackMsg', {
              type: 'error',
              title: this.$t('settings.admincenter.labels:submit.error')
            })
          }
        }).finally(() => { this.attemptingSave = false })
    }
  },
  watch: {
    changeCities (name) {
      if (name) this.getCitiesUs({ city: name, state: null })
    }
  }
}
</script>
<template>
  <div class="company-us--form" v-if="isReady">
    <section-block title="admin.center.company:form.indentify">
      <input-field v-model="formData.socialName" outlined persistent-placeholder :validation="$v.formData.socialName" :label="$t('admin.center.company:form.indentify.social.name')"></input-field>
      <input-field v-model="formData.fantasyName" outlined persistent-placeholder :validation="$v.formData.fantasyName" :label="$t('admin.center.company:form.indentify.fantasy.name')"></input-field>
      <input-field v-model="formData.ein" outlined persistent-placeholder :validation="$v.formData.ein" :label="$t('admin.center.company:form.indentify.ein')" mask="##.#######"></input-field>
    </section-block>
    <section-block title="admin.center.company:form.contact">
      <input-field v-model="formData.email" outlined persistent-placeholder :validation="$v.formData.email" :label="$t('admin.center.company:form.contact.email')"></input-field>
      <input-field v-model="formData.phone" outlined persistent-placeholder :validation="$v.formData.phone" :label="$t('admin.center.company:form.contact.phone')" mask="(###) ###-####"></input-field>
    </section-block>
    <section-block title="admin.center.company:form.address">
      <div class="flex-horizontal small-info-block-2">
        <input-field v-model="formData.zipcode" outlined persistent-placeholder :validation="$v.formData.zipcode" :label="$t('admin.center.company:form.address.zipcode')" mask="#####"></input-field>
        <select-field
          outlined
          :items="states"
          v-model="formData.state"
          item-value="id"
          :label="$t('admin.center.company:form.address.state')"
        />
      </div>
      <div class="flex-horizontal small-info-block-2">
        <autocomplete
            :search-input.sync="changeCities"
            :items="cities"
            hide-no-data
            outlined
            clearable
            v-model="formData.city"
            prepend-inner-icon=""
            :label="$t('admin.center.company:form.address.city')"
          />
        <input-field v-model="formData.street" outlined persistent-placeholder :label="$t('admin.center.company:form.address')"></input-field>
      </div>
    </section-block>
    <div class="company-us--actions">
      <v-btn class="btn transform-unset" :disabled="$v.$invalid || attemptingSave" :color="getPrimaryColor" :dark="!($v.$invalid || attemptingSave)" @click="updateCompanyDetailsUs">
        <v-icon v-if="!attemptingSave" class="mr-2" size="20" color="#fff">mdi-content-save-outline</v-icon>
        {{ $t(attemptingSave ? 'global:wait' : 'global:save.changes') }}
      </v-btn>
    </div>
  </div>
</template>
<style lang="scss">
.company-us--form {
  .company-us--actions {
    text-align: right;
    margin-top: 40px;
    margin-bottom: 100px;
  }
}
</style>
