<script>
import CompanyBrForm from './CompanyBrForm'
import CompanyUsForm from './CompanyUsForm'
import Breadcrumbs from '@/components/general/Breadcrumbs'
export default {
  components: {
    CompanyBrForm,
    CompanyUsForm,
    Breadcrumbs
  },
  name: 'Company',
  computed: {
    breadcrumbsPaths () {
      const path = [{ text: 'admin.center:menu.1:title', href: this.$route.path }]
      return path
    },
    getCompanyCountry () {
      return this.selectedWorkspace.region
    }
  }
}
</script>
<template>
  <section class="company--container center-small">
    <div class="default-header">
      <breadcrumbs :items="breadcrumbsPaths"/>
      <h2>{{ $t('admin.center.company:title') }}</h2>
    </div>
    <div class="company--body">
      <company-br-form v-if="getCompanyCountry === 'BRA'"></company-br-form>
      <company-us-form v-if="getCompanyCountry === 'USA'"></company-us-form>
    </div>
  </section>
</template>
